import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  margin: 2% 0 0 0;
  @media only screen and (max-width: 992px) {
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 7vw !important;
    }
  }
`;

export const AboutUsContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  div {
    margin: 0;
  }
`;
export const Row = styled.div`
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;
export const Column66 = styled.div`
  float: left;
  width: 55%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 10px;
  }
`;
export const Column33 = styled.div`
  float: left;
  padding: 20px 0;
  @media only screen and (max-width: 992px) {
    width: 100%;
    div {
      width: 100% !important;
      height: 36vh !important;
      display: flex !important;
      margin: auto;

      img {
        margin: auto;
      }
    }
  }
`;
