import React from "react";
import App from "./app";
import SEO from "../components/seo";
import * as Global from "../css/globalStyle";
import * as Styled from "../css/stureplansgruppenStyle";
import { useStaticQuery, graphql } from "gatsby";
import SmallTalkComponent from "../components/smalltalk";
import Img from "gatsby-image";
import { useGlobal } from "reactn";
import getLang from "../components/scripts/getLang";
import SmallTalkImage from "../images/smalltalks.png";
import Hero from "../components/hero";

const Content = ({ data, english }) => {
  let {
    brdtext1,
    brdtext2,
    brdtext3,
    bild1,
    bild2,
    bild3,
    hllbarhetsrapportPdf
  } = data;

  return (
    <div>
      <Styled.AboutUsContainer>
        <Styled.Row className="row">
          <Styled.Column66>
            {brdtext1 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: brdtext1.childContentfulRichText.html
                }}
              />
            ) : (
              <div> </div>
            )}
          </Styled.Column66>
          <Styled.Column33>
            <Img fixed={bild1.fixed} />
          </Styled.Column33>
        </Styled.Row>

        <Styled.Row className="wrapReverse row">
          <Styled.Column33>
            <Img fixed={bild2.fixed} />
          </Styled.Column33>
          <Styled.Column66>
            {brdtext2 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: brdtext2.childContentfulRichText.html
                }}
              />
            ) : (
              <div> </div>
            )}
            <a
              href={hllbarhetsrapportPdf.file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Global.button>
                {english ? "Read Report Here" : "Läs hela rapporten här"}
              </Global.button>
            </a>
          </Styled.Column66>
        </Styled.Row>

        <Styled.Row className="row">
          <Styled.Column66>
            {brdtext3 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: brdtext3.childContentfulRichText.html
                }}
              />
            ) : (
              <div> </div>
            )}
          </Styled.Column66>
          <Styled.Column33>
            {bild3 ? <Img fixed={bild3.fixed} /> : null}
          </Styled.Column33>
        </Styled.Row>
      </Styled.AboutUsContainer>
    </div>
  );
};

const Stureplansgruppen = () => {
  let [english] = useGlobal("English");
  let data = useStaticQuery(
    graphql`
      query {
        allContentfulOmOssSida {
          edges {
            node {
              node_locale
              heroRubrik
              seoTitel
              seoDescription
              heroUnderRubrik {
                heroUnderRubrik
              }
              heroBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              brdtext1 {
                childContentfulRichText {
                  html
                }
              }
              brdtext2 {
                childContentfulRichText {
                  html
                }
              }
              brdtext3 {
                childContentfulRichText {
                  html
                }
              }
              bild1 {
                fixed(width: 580, height: 400) {
                  ...GatsbyContentfulFixed
                }
              }
              bild2 {
                fixed(width: 580, height: 400) {
                  ...GatsbyContentfulFixed
                }
              }
              bild3 {
                fixed(width: 580, height: 400) {
                  ...GatsbyContentfulFixed
                }
              }
              hllbarhetsrapportPdf {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  );
  let newData = getLang(english, data.allContentfulOmOssSida);
  let { seoTitel,seoDescription } = newData[0].node;

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero data={newData} heroTema={null} img={"img1"} />

      <Styled.ContentContainer>
        <Content english={english} data={newData[0].node} />

        <Global.CenterDiv>
          <img
            style={{ margin: "20px 0 0 0" }}
            alt="Smalltalk"
            className="smallTalkImage"
            src={SmallTalkImage}
          ></img>

          {english ? (
            <p>
              Meet all exciting personalities who create unforgettable
              experiences and daily greets you.
              <br /> Here we tell their stories.
            </p>
          ) : (
            <p>
              Möt alla spännande personligheter som skapar minnesvärda
              upplevelser och dagligen möter dig.
              <br /> Här berättar vi deras historier.
            </p>
          )}
        </Global.CenterDiv>

        <SmallTalkComponent english={english} />
      </Styled.ContentContainer>
    </App>
  );
};

export default Stureplansgruppen;
